<!-- 动态加载,数据请求,客户端渲染,loading -->
<template>
  <div>
    <Loading v-show="injectionLoading" />

    <div v-show="renderData">
      <div v-for="news in renderData" :key="news.id" class="news-item">
        <p>{{ news.id }}. {{ news.title }}</p>
        <p>{{ news.summary }}</p>
        <p>{{ news.date }} | {{ news.author }} | {{ news.source }}</p>
      </div>
      <button @click="loadMore">
        Load More
      </button>
    </div>
    <!-- <Loading v-else /> -->
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';

export default {
  components: {
    Loading
  },
  serverPrefetch () {
    console.log('is  serverPrefetch');
    return this.fetchData(1);
  },
  data () {
    return {
      injectionLoading: false,
      isLoading: false,  // 新增的标志位
      currentPage: 1  // 当前页数
    }
  },
  computed: {
    renderData () {
      return this.$store.state.render.renderData;
    }
  },
  beforeMount () {
    // TODO: 周一要来处理这里的数据不重复请求! this.$store.state.render.initStart
    console.log('beforeMount step');
    console.log(`state.render.initStart:${this.$store.state.render.initStart}`);
    if (typeof window !== 'undefined' && !this.$store.state.render.initStart) {
      console.log('准备执行beforeMount获取数据请求');
      this.fetchData(this.currentPage);
    }
  },
  created () {
    if (typeof window === 'undefined') {
      console.log('server created step');
    } else {
      console.log(`state.render.initStart:${this.$store.state.render.initStart}`);
      console.log('client created step');
      window.addEventListener('scroll', this.onScroll);
    }
  },
  mounted () {
    console.log('mounted step');
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    setLoading (status) {
      this.injectionLoading = status;
    },
    async fetchData (page) {
      if (this.isLoading) return; // 如果正在加载则返回
      this.isLoading = true; // 设置正在加载标志位
      this.setLoading(true);
      try {
        await this.$store.dispatch('render/fetchMoreDataServer', { page });
        console.log('收到请求,这里可以处理数据');
        this.currentPage = page;  // 更新当前页数
      } catch (error) {
        console.error(error);
      } finally {
        this.setLoading(false);
        this.isLoading = false; // 请求结束，重置标志位
      }
    },
    loadMore () {
      if (this.isLoading) return; // 如果正在加载则返回
      console.log('more');
      console.log(this.$store);
      const nextPage = this.currentPage + 1;
      console.log('Fetching page:', nextPage);
      this.fetchData(nextPage);
    },
    onScroll () {
      console.log(window.innerHeight, window.scrollY, document.body.offsetHeight);
      if (window.innerHeight + window.scrollY + 200 >= document.body.offsetHeight) {
        this.loadMore();
      }
    }
  }
}
</script>
